import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { AttendanceService, Channel, ConfirmationComponent, Contact, ContactEditComponent, ContactGroup, ContactGroupService, ContactService, CustomFieldContact, CustomFieldService, DepartmentService, Note, NoteService, Pager, Tag, TagService, TeamService, TypeFieldEnum } from 'lib-trend-core';
import { debounceTime, distinctUntilChanged, Observable, Subject, switchMap } from 'rxjs';
import { ContactViewNoteEditComponent } from './contact-view-note-edit/contact-view-note-edit.component';
import { E } from '@angular/cdk/keycodes';
import { el } from 'date-fns/locale';

@Component({
  selector: 'contact-view-component',
  templateUrl: './contact-view.component.html',
  styleUrl: 'contact-view.component.scss'
})
export class ContactViewComponent extends ContactEditComponent implements OnInit {
  override contact: Contact;
  disabledCustomInput: boolean = true;
  navType: string = 'notes';
  contactDetails: any;
  tags: Array<Tag> = new Array<Tag>();
  showSelectTags: boolean = true;

  private totalAttendances;

  fields: Array<{ id: number; name: string; value: string }> = [];
  pager: Pager<Note> = new Pager<Note>({ perPage: 10 });
  listObservable: Observable<Pager<Note>>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  customFieldsView: Array<CustomFieldContact> = [];
  originalGroups: ContactGroup[] = [];
  fieldOptions: string[] = [];

  allCustomFields: any[] = [];
  isEditMode = false;
  editedContact: Contact = {} as Contact;
  constructor(
    injector: Injector,
    public attendanceService: AttendanceService,
    public noteService: NoteService,
    public customFieldService: CustomFieldService,
    public dialog: MatDialog,
    public contactGroupService: ContactGroupService,
    dialogRef: MatDialogRef<ContactViewComponent>,
    contactService: ContactService,
    contactGrupoService: ContactGroupService,
    departmentService: DepartmentService,
    tagService: TagService,
    teamService: TeamService,
    fieldsCustomService: CustomFieldService,
    @Inject(MAT_DIALOG_DATA) override data: Contact,

  ) {
    super(
      injector,
      dialogRef,
      contactService,
      contactGrupoService,
      departmentService,
      tagService,
      teamService,
      fieldsCustomService,
      data
    );
  }

  override ngOnInit() {
    this.createForm();
    this.loadCustomFields();
    this.loadFieldOptions();
    const idContact = super.getParam('id');
    if (!!idContact) {
      this.loadContactDetails(idContact);
    }
    this.tagService.getList().subscribe(tag => this.tags = tag);
    this.getPagerNote();
    this.loadGroups();
  }

  protected override createForm(): void {
    this.formGroup = this.formBuilder.group({
      attendant: [null],
      department: [null],
    });
  }

  setupObservableSearch() {
    this.listObservable = this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.noteService.getAll(this.pager.page, this.pager.perPage, term, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<Note>) => {
      this.pager = pager;
    });
  }

  getPagerNote(): void {
    this.loadingContent = true;

    const idContact = super.getParam('id');
    if (!!idContact) {
      this.searchParams = { contact: idContact };
    }

    this.noteService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe({
      next: (pager: Pager<Note>) => {
        this.pager = pager;
        this.setupObservableSearch();
        this.loadingContent = false;
      },
    });
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getPagerNote();
  }

  delete(item: Contact): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.contactService.delete(item._id).subscribe({
          next: () => {
            this.router.navigate(['/contact']);
            this.alertService.success('Contato excluído com sucesso.');
          },
          error: err => this.alertService.error(err.error.message)
        })
      }
    });
  }

  loadContactDetails(contactId: string) {
    this.contactService.getContactDetailsWithCounts(contactId).subscribe({
      next: (details: any[]) => {
        if (details && details.length > 0) {
          this.contactService.getById(contactId).subscribe({
            next: (contact: Contact) => {
              this.contact = contact;
              this.editedContact = { ...contact };
              this.customFieldsView = contact.customFields || [];
              this.contactDetails = details;
              this.formGroup.get('attendant').setValue(this.editedContact.customerPortfolio?.user);
              this.formGroup.get('department').setValue(this.editedContact.customerPortfolio?.department);
              if (this.editedContact.customerPortfolio?.department) {
                this.loadAttendant();
              }
            },
            error: (err) => this.alertService.error(err.error.message)
          });
        }
      },
      error: (err) => this.alertService.error(err.error.message)
    });
  }
  getGenderDisplay(gender: string): string {
    const genderMap = {
      'MALE': 'Masculino',
      'FEMALE': 'Feminino',
      'OTHER': 'Outro',
    };
    return genderMap[gender] || gender;
  }

  formatTin(tin: string): string {
    if (!tin) return '-';
    return tin.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  getGroupsDisplay(groups: any[]): string {
    if (!groups || groups.length === 0) return '-';
    return groups.map(group => group.name || group).join(', ');
  }

  getTagsDisplay(tags: any[]): string {
    if (!tags || tags.length === 0) return '-';
    return tags.map(tag => tag.title || tag).join(', ');
  }

  loadCustomFields() {
    this.customFieldService.getList().subscribe(fields => {
      this.fieldOptions = fields.map(field => field.name);
      this.allCustomFields = fields;
    });
  }

  loadFieldOptions() {
    this.customFieldService.getList().subscribe(fields => {
      this.fieldOptions = fields.map(field => field.name);
    });
  }

  //GETTERS

  get notes(): any[] {
    if (!this.contactDetails?.[0]?.notes) {
      return [];
    }
    return this.contactDetails[0].notes;
  }

  get contactTags(): any[] {
    if (!this.contactDetails?.[0]?.tags) {
      return [];
    }
    return this.contactDetails[0].tags;
  }

  get totalNotes(): number {
    return this.contactDetails?.[0]?.totalNotes || 0;
  }

  get totalTags(): number {
    return this.contactDetails?.[0]?.totalTags || 0;
  }

  get countAttendance(): number {
    return this.contactDetails?.[0]?.countAttendance || 0;
  }

  get availableTags(): Tag[] {
    return this.tags ?? [];
  }

  get selectedTags(): Tag[] {
    console.log(this.contactDetails)
    return this.contactDetails?.[0]?.tags || [];
  }

  trackByTagId(index: number, tag: Tag): string {
    return tag._id;
  }

  changeNavType(type: string) {
    this.navType = type;
  }

  removeTag(tagToRemove: Tag) {
    if (this.contact && this.contact.tags) {
      const updatedTags = this.contact.tags.filter(tag => tag._id !== tagToRemove._id);

      const updatedContact = {
        ...this.contact,
        tags: updatedTags
      };

      this.contactService.update(this.contact._id, updatedContact).subscribe({
        next: (response) => {
          this.contact.tags = updatedTags;
          this.alertService.success('Tag removida com sucesso');
          this.loadContactDetails(this.contact._id);
        },
        error: (err) => {
          this.alertService.error('Erro ao remover tag');
          this.loadContactDetails(this.contact._id);
        }
      });
    }
  }

  isTagSelected(tagId: string): boolean {
    return this.contact?.tags?.some(tag => tag._id === tagId) || false;
  }

  selected(event: MatSelectChange): void {
    if (this.contact && event.value) {
      const tagExists = this.contact.tags?.some(tag => tag._id === event.value._id);

      if (!tagExists) {
        this.contact.tags = this.contact.tags || [];
        this.contact.tags.push(event.value);

        this.contactService.update(this.contact._id, this.contact).subscribe({
          next: (updatedContact) => {
            this.alertService.success('Tag adicionada com sucesso');
            this.loadContactDetails(this.contact._id);
          },
          error: (err) => {
            this.alertService.error('Erro ao adicionar tag');
            this.contact.tags = this.contact.tags.filter(tag => tag._id !== event.value._id);
          }
        });
      }
    }
    this.showSelectTags = true;
  }

  getChannelContact(contactId: string): string {
    if (this.totalAttendances && this.totalAttendances.length > 0) {
      const channel = this.totalAttendances
        .filter((value: { _id: string, count: number, channel: Channel }) => value && value._id === contactId)
        .map((value: { _id: string, count: number, channel: Channel }) => value.channel)
        .reduce((channelAcc, channelCurrent) => channelCurrent || channelAcc, null);

      return (channel && channel.name) && (channel.removed !== true) ? channel.name : '-';
    }
    return '-';
  }

  addField(): void {
    const newField = {
      id: this.fields.length + 1,
      name: '',
      value: '',
    };
    this.fields.push(newField);
  }

  removeField(id: number): void {
    this.fields = this.fields.filter(field => field.id !== id);
  }

  addCustomField() {
    const newFieldName = 'Adicionar Campo';
    const exists = this.customFieldsView.some(field => field.name === newFieldName);
    if (!exists) {
      this.customFieldService.getList().subscribe(fields => {
        const availableField = fields.find(field => !this.customFieldsView.some(cf => cf._id === field._id));
        if (availableField) {
          const newField: CustomFieldContact = {
            _id: availableField._id,
            name: '',
            description: availableField.description || '',
            type: availableField.type || TypeFieldEnum.TEXT,
            value: null
          };
          this.customFieldsView.push(newField);
        } else {
          this.alertService.error('Não há mais campos personalizados disponíveis.');
        }
      });
    } else {
      this.alertService.error('Campo personalizado já existe.');
    }
  }

  removeCustomField(index: number) {
    this.customFieldsView.splice(index, 1);
  }

  isFieldSelected(fieldName: string, currentIndex: number): boolean {
    return this.customFieldsView.some((field, index) =>
      index !== currentIndex && field.name === fieldName
    );
  }

  onCustomFieldChange(index: number, fieldName: string) {
    const field = this.customFieldsView[index];
    if (!fieldName) {
      this.alertService.error('O nome do campo é obrigatório');
      return;
    }

    const selectedField = this.allCustomFields.find(f => f.name === fieldName);
    if (selectedField) {
      field._id = selectedField._id;
      field.name = fieldName;
      field.type = selectedField.type;
      field.description = selectedField.description;
    }
  }

  saveCustomFields() {
    const missingFields = this.customFieldsView.filter(field => !field.name || !field.value);

    if (missingFields.length > 0) {
      this.alertService.warning('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    const updatedContact = { ...this.contact, customFieldsView: this.customFieldsView };
    this.contactService.update(this.contact._id, updatedContact).subscribe({
      next: () => {
        this.alertService.success('Campos personalizados salvos com sucesso!');
        this.loadContactDetails(this.contact._id);
      },
      error: (err) => {
        this.alertService.error('Erro ao salvar campos personalizados.');
      }
    });
  }

  getContactStatus(status: string | undefined): string {
    switch (status) {
      case 'VALIDATED':
        return 'Validado';
      case 'NOT_VALIDATED':
        return 'Não Validado';
      case 'INVALID':
        return 'Inválido';
      default:
        return 'Desconhecido';
    }
  }

  loadGroups() {
    this.searchParams = {
      company: this.getIDCurrentCompany(),
    };

    this.contactGroupService.getList().subscribe({
      next: (value: Array<ContactGroup>) => {
        this.originalGroups = value;
        this.setupObservableSearch();
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  addGroup(originalGroups: ContactGroup) {
    if (!this.contact.groups.some(group => group._id === originalGroups._id)) {
      this.contact.groups.push(originalGroups);
    } else {
      this.alertService.warning('Grupo já adicionado.');
    }
  }

  removeGroup(groupToRemove) {
    this.contact.groups = this.contact.groups.filter(group => group !== groupToRemove);
  }

  saveContact(item: Contact) {
    if (!this.editedContact.name) {
      this.alertService.warning('O campo "Nome" é obrigatório.');
      return;
    }

    if (!this.editedContact.phone) {
      this.alertService.warning('O campo "Telefone" é obrigatório.');
      return;
    }

    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup);
      return;
    }

    if(this.formGroup.get('attendant').value && this.formGroup.get('department').value) {
      this.editedContact.customerPortfolio = {
        user: this.formGroup.get('attendant').value,
        department: this.formGroup.get('department').value
      }
    } else {
      this.editedContact.customerPortfolio = null;
    }
    this.editedContact.groups = this.contact.groups.map(group => ({ ...group }));

    this.contactService.update(this.contact._id, this.editedContact).subscribe({
      next: () => {
        this.loading = false;
        this.loadContactDetails(this.contact._id);
        this.alertService.success('Contato salvo com sucesso.');
        this.cancelEdit();
      },
      error: () => (this.loading = false),
    });
  }

  editContact(item: Contact): void {
    this.editedContact = { ...this.contact };
    this.isEditMode = true;
  }

  cancelEdit(): void {
    this.isEditMode = false;
    this.editedContact = { ...this.contact };
  }

  openNoteDialog(note?: Note) {
    const dialogRef = this.dialog.open(ContactViewNoteEditComponent, {
      width: '600px',
      data: {
        note: note,
        contact: this.contact,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadContactDetails(this.contact._id);
        this.getPagerNote()
      }
    });
  }

  removeNote(note: Note): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.noteService.delete(note._id).subscribe({
          next: () => {
            this.alertService.success('Nota excluída com sucesso.');
            this.loadContactDetails(this.contact._id);
            this.getPagerNote();
          },
          error: err => this.alertService.error(err.error.message)
        });
      }
    });
  }


  openNewConversation() {
    this.router.navigate(['/attendance/panel'], {
      queryParams: { contactId: this.contact._id }
    });
  }

  openAttendanceNote(attendanceId: string, status: string) {
    if (attendanceId && status) {
      this.router.navigate(['/attendance/panel'], {
        queryParams: {
          id: attendanceId,
          status: status
        }
      });
    }
  }
}