import { Component, Injector, Input, ViewEncapsulation } from "@angular/core";
import { AbstractComponent, Attendance, AttendanceService, AttendanceStatusEnum, Pager } from "lib-trend-core";
import { finalize, takeUntil } from "rxjs";
import { AttendanceHistoryComponent, AttendanceHistoryData } from "../../../attendance/attendance-history/attendance-history.component";
import { MatDialog } from "@angular/material/dialog";
import { AssignedUserComponent } from "../../../attendance/attendance-panel/attendance-panel-components/assigned-user/assigned-user.component";

@Component({
  selector: 'contact-view-attendance-list-component',
  templateUrl: './contact-view-attendance-list.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ContactViewAttendanceListComponent extends AbstractComponent {

  @Input() idContact: string;

  pager: Pager<Attendance> = new Pager<Attendance>({ perPage: 5 });

  constructor(
    injector: Injector,
    public attendanceService: AttendanceService,
    public dialog: MatDialog,
  ) {

    super(injector)
  }

  ngOnInit(): void {
    this.getAttendanceHistory();
  }

  loadPage(page: number) {

    this.pager.page = page;
    this.getAttendanceHistory();
  }

  private getAttendanceHistory() {
    this.loadingContent = true;

    this.searchParams = {
      company: super.getIDCurrentCompany(),
      contact: this.idContact,
    };

    this.attendanceService.getAttendanceHistory(
      this.pager.page,
      this.pager.perPage,
      this.searchString,
      this.searchParams
    )
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.loadingContent = false;
        })
      )
      .subscribe({
        next: (pager: Pager<Attendance>) => {
          this.pager = pager;
        }
      });
  }

  showDetailsAttendanceHitory(item: Attendance) {
    this.dialog.open(AttendanceHistoryComponent, {
      width: '90vw',
      height: '95vh',
      data: <AttendanceHistoryData>{
        openAttendanceHistoryUnique: true,
        idContact: item.contact._id,
        idAttendance: item._id,
      }
    });
  }

  openAssignedUserTransferModal(attendance: Attendance) {
    const dialogRef = this.dialog.open(AssignedUserComponent, {
      width: '600px',
      data: attendance
    });
    dialogRef.afterClosed().subscribe(result => {
      //  this.getList()
    });
  }

  showTransferButton(attendance: Attendance): boolean {
    return attendance.status === AttendanceStatusEnum.PENDING || attendance.status === AttendanceStatusEnum.IN_PROGRESS
      || attendance.status === AttendanceStatusEnum.OVERTIME || attendance.status === AttendanceStatusEnum.PAUSED;
  }
} 