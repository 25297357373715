<div class="flex flex-1 flex-col px-5">
  <div class="mb-4">
    <h1 class="font-black text-[20px] text-black">Contatos e Grupos</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">
      Organize seus contatos e grupos conforme sua processo de atendimento
    </h6>
    <hr />
  </div>
  <div>
    <div class="w-full mt-4">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-2 sm:gap-3">
        <card-graphic-small-component [label]="'Total de notas'" [total]="totalNotes.toString()" class="w-full">
        </card-graphic-small-component>

        <card-graphic-small-component [label]="'Total de tags'" [total]="totalTags.toString()" class="w-full">
        </card-graphic-small-component>

        <card-graphic-small-component [label]="'Atendimentos'" [total]="countAttendance.toString()" class="w-full">
        </card-graphic-small-component>

        <card-graphic-small-component [label]="'Campos customizados'"
          [total]="(customFieldsView.length  || 0).toString()" class="w-full w-min-[50px]">
        </card-graphic-small-component>

        <card-graphic-small-component [label]="'Total de grupos'" [total]="(contact?.groups?.length || 0).toString()"
          class="w-full">
        </card-graphic-small-component>
      </div>
    </div>
  </div>

  <section class="mt-3">
    <div class="grid lg:grid-cols-10 grid-cols-1 gap-6 w-full h-full items-stretch">
      <div class="lg:col-span-10">
        <div class="flex">
          <input id="tab-one" type="radio" name="tabs" class="peer/tab-one hidden" (click)="changeNavType('notes')"
            [checked]="navType === 'notes'" />
          <label for="tab-one"
            class="text-base font-medium hover:text-[#4213F6] peer-checked/tab-one:border-b-2 peer-checked/tab-one:border-[#4213F6] peer-checked/tab-one:text-[#4213F6] cursor-pointer px-4 py-2">
            Notas
          </label>

          <input id="tab-two" type="radio" name="tabs" class="peer/tab-two hidden" (click)="changeNavType('tags')"
            [checked]="navType === 'tags'" />
          <label for="tab-two"
            class="text-base font-medium hover:text-[#4213F6] peer-checked/tab-two:border-b-2 peer-checked/tab-two:border-[#4213F6] peer-checked/tab-two:text-[#4213F6] cursor-pointer px-4 py-2">
            Tags
          </label>

          <input id="tab-three" type="radio" name="tabs" class="peer/tab-three hidden"
            (click)="changeNavType('attendance')" [checked]="navType === 'attendance'" />
          <label for="tab-three"
            class="text-base font-medium hover:text-[#4213F6] peer-checked/tab-three:border-b-2 peer-checked/tab-three:border-[#4213F6] peer-checked/tab-three:text-[#4213F6] cursor-pointer px-4 py-2">
            Atendimentos
          </label>

          <input id="tab-four" type="radio" name="tabs" class="peer/tab-four hidden"
            (click)="changeNavType('customFields')" [checked]="navType === 'customFields'" />
          <label for="tab-four"
            class="text-base font-medium hover:text-[#4213F6] peer-checked/tab-four:border-b-2 peer-checked/tab-four:border-[#4213F6] peer-checked/tab-four:text-[#4213F6] cursor-pointer px-4 py-2">
            Campos Personalizados
          </label>

          <input id="tab-groupContact" type="radio" name="tabs" class="peer/tab-groupContact hidden"
            (click)="changeNavType('groupContact')" [checked]="navType === 'groupContact'" />
          <label for="tab-groupContact"
            class="text-base font-medium hover:text-[#4213F6] peer-checked/tab-groupContact:border-b-2 peer-checked/tab-groupContact:border-[#4213F6] peer-checked/tab-groupContact:text-[#4213F6] cursor-pointer px-4 py-2">
            Grupos de Contatos
          </label>

          @if (getModuleCustomerPortfolioCompanyCurrentUser()) {
          <input id="tab-customerPortfolio" type="radio" name="tabs" class="peer/tab-customerPortfolio hidden"
            (click)="changeNavType('customerPortfolio')" [checked]="navType === 'customerPortfolio'" />
          <label for="tab-customerPortfolio"
            class="text-base font-medium hover:text-[#4213F6] peer-checked/tab-customerPortfolio:border-b-2 peer-checked/tab-customerPortfolio:border-[#4213F6] peer-checked/tab-customerPortfolio:text-[#4213F6] cursor-pointer px-4 py-2">
            Carteira de Clientes
          </label>
          }
        </div>
      </div>

      <div class="lg:col-span-10 flex gap-6 h-full">
        <div class="flex-1 h-full">
          <div class="w-full bg-[#fff] p-3 rounded-lg shadow-global mb-2 min-h-[567px]">
            <div *ngIf="navType === 'notes'">

              <div class="flex justify-between ">
                <div class="mb-[15px]">
                  <h1 class="font-bold text-[1.2rem] mt-[10px] text-black">Gestão de Notas</h1>
                  <h6 class="text-zinc-400 font-light text-sm">
                    Abaixo estão as opções para editar ou excluir suas notas conforme necessário.
                  </h6>
                </div>
                <button
                  class="h-[45px] flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[200px]"
                  (click)="openNoteDialog()">
                  <mat-icon class="mr-2">add</mat-icon>
                  Adicionar Nota
                </button>
              </div>
              <div class="w-full flex flex-wrap gap-[12px] mt-2">
                <div class="inline-block min-w-full py-2 align-middle">
                  <div class="overflow-visible rounded-lg bg-white" [ngClass]="{
                      'ring-1 ring-black ring-opacity-5 border-gray-100 border-2':
                        pager.list?.length
                    }">
                    <content-loading-list *ngIf="loadingContent"></content-loading-list>

                    <empty-records-component *ngIf="!loadingContent && !pager.list?.length"
                      [message]="'Nenhuma nota encontrada'" [subMessage]="
                        'Adicione uma nova nota para começar a registrar suas informações'
                      ">
                    </empty-records-component>

                    <table *ngIf="!loadingContent && pager.total > 0"
                      class="min-w-full divide-gray-100 divide-y-[3px] relative">
                      <thead class="bg-gray-50">
                        <tr>
                          <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">
                            Data
                          </th>
                          <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">
                            Nota
                          </th>
                          <th scope="col" class="w-16"></th>
                        </tr>
                      </thead>
                      <tbody class="divide-gray-100 divide-y-[3px] bg-white">
                        <tr *ngFor="let note of pager.list">
                          <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900">
                            {{ note.createdAt | date : "shortDate" }}
                          </td>
                          <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900">
                            {{
                            note.description || "Sem descrição"
                            | truncate : 50
                            }}
                          </td>
                          <td class="relative">
                            <div class="inline-block text-left relative" x-data="{ menu: false }"
                              style="position: relative">
                              <button x-on:click="menu = ! menu" type="button"
                                class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
                                id="menu-button" aria-expanded="true" aria-haspopup="true">
                                <span class="sr-only"></span>
                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                  fill="currentColor" aria-hidden="true">
                                  <path
                                    d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                                </svg>
                              </button>
                              <div class="p-2" x-show="menu" x-on:click.away="menu = false"
                                style="position: absolute; z-index: 9999"
                                class="rounded-lg ring-1 ring-black ring-opacity-5 shadow-lg divide-y focus:outline-none bg-white right-0 mt-2"
                                role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                                <div class="w-[195px]" role="none">
                                  <a href="javascript:void(0)" *ngIf="note.attendance" (click)="
                                      openAttendanceNote(
                                        note.attendance._id,
                                        note.attendance.status
                                      )
                                    "
                                    class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                                    role="menuitem" tabindex="-1" id="menu-item-0">
                                    Ir para o atendimento
                                  </a>
                                  <a href="javascript:void(0)"
                                    class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                                    role="menuitem" tabindex="-1" id="menu-item-0" (click)="
                                      openNoteDialog(note);
                                      $event.stopPropagation()
                                    ">
                                    Visualizar
                                  </a>
                                  <a href="javascript:void(0)"
                                    class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                                    role="menuitem" tabindex="-1" id="menu-item-0" (click)="
                                      removeNote(note); $event.stopPropagation()
                                    ">
                                    Remover
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr *ngIf="notes?.length === 0">
                          <td colspan="3" class="text-center py-4 text-sm text-gray-500">
                            Nenhuma nota encontrada
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <paginator *ngIf="pager.total > pager.perPage" class="flex justify-content-center mt-3 mb-3"
                    [pager]="pager" (onPager)="loadPage($event)">
                  </paginator>
                </div>
              </div>
            </div>
            <div *ngIf="navType === 'tags'">
              <div class="mb-[15px]">
                <h1 class="font-bold text-[1.2rem] mt-[10px] text-black">Gestão de Tags do Contato</h1>
                <h6 class="text-zinc-400 font-light text-sm">
                  Abaixo você encontrará as opções para editar ou excluir as tags vinculadas a este contato.
                </h6>
              </div>

              <mat-select class="input-field" (selectionChange)="selected($event)" placeholder="Selecione">
                <mat-option *ngFor="let tag of availableTags; trackBy: trackByTagId" [value]="tag">
                  {{ tag.title }}
                </mat-option>
              </mat-select>
              <empty-records-component *ngIf="selectedTags.length === 0" [message]="'Nenhuma tag encontrado'"
                [subMessage]="'Adicione um novo tag personalizado acima'">
              </empty-records-component>
              <div class="flex flex-wrap gap-1 pt-4">
                <mat-chip-row *ngFor="let tag of selectedTags; trackBy: trackByTagId" class="mr-1 mb-1"
                  [ngStyle]="{ 'background-color': tag.bgColor || '#fff' }" (removed)="removeTag(tag)"
                  [editable]="false">
                  <span [ngStyle]="{ color: tag.fontColor || '#fff' }">
                    {{ tag.title }}
                  </span>
                  <button matChipRemove [attr.aria-label]="'Remover ' + tag.title">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
              </div>
            </div>

            <div class="m-auto min-h-[500px] w-full" *ngIf="navType === 'attendance'">
              <div class="mb-[15px]">
                <h1 class="font-bold text-[1.2rem] mt-[10px] text-black">Gestão de Atendimentos</h1>
                <h6 class="text-zinc-400 font-light text-sm">
                  Abaixo você encontrará os Atendimentos desse contato.
                </h6>
              </div>
              <contact-view-attendance-list-component
                [idContact]="contact._id"></contact-view-attendance-list-component>
            </div>
            <div *ngIf="navType === 'customFields'">
              <div class="flex justify-between">
                <div class="mb-[15px]">
                  <h1 class="font-bold text-[1.2rem] mt-[10px] text-black">Gestão de Campo Personalizado</h1>
                  <h6 class="text-zinc-400 font-light text-sm">
                    Abaixo você encontrará as opções para editar ou excluir os campo personalizado.
                  </h6>
                </div>
                <button
                  class="h-[45px] flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[200px]"
                  (click)="addCustomField()">
                  <mat-icon class="mr-2">add</mat-icon>
                  Novo Campo
                </button>
              </div>

              <div class="flex flex-col gap-4">
                <div *ngFor="let field of customFieldsView; let i = index" class="flex flex-col w-full">
                  <div class="flex gap-4">
                    <div class="flex flex-col w-1/2">
                      <label class="font-medium text-black">Nome</label>
                      <mat-select class="input-field" [(ngModel)]="field.name"
                        (selectionChange)="onCustomFieldChange(i, $event.value)">
                        <mat-option *ngFor="let option of fieldOptions" [value]="option"
                          [disabled]="isFieldSelected(option, i)">
                          {{ option }}
                        </mat-option>
                      </mat-select>
                    </div>

                    <div class="flex flex-col w-1/2">
                      <label class="font-medium text-black">{{
                        field.name || "Valor"
                        }}</label>
                      <input class="input-field" [(ngModel)]="field.value" placeholder="Digite o valor..." />
                    </div>

                    <div class="flex items-end">
                      <button mat-icon-button color="warn" class="mb-2" (click)="removeCustomField(i)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>

                <div *ngIf="customFieldsView.length === 0" class="text-center py-4 text-sm text-gray-500">
                  <empty-records-component [message]="'Nenhum campo personalizado encontrado'"
                    [subMessage]="'Adicione um novo campo personalizado acima'">
                  </empty-records-component>
                </div>
              </div>
              <div class="flex justify-end mt-4">
                <button *ngIf="customFieldsView.length !== 0"
                  class="h-[45px] flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[200px]"
                  (click)="saveCustomFields()">
                  Salvar Campos
                </button>
              </div>
            </div>


            <div *ngIf="navType === 'groupContact'">
              <div class="mb-[15px]">
                <h1 class="font-bold text-[1.2rem] mt-[10px] text-black">Gestão de Grupos de Contatos</h1>
                <h6 class="text-zinc-400 font-light text-sm">
                  Abaixo estão as opções para adicionar ou excluir seus Grupos de Contatos .
                </h6>
              </div>
              <mat-select class="input-field" (selectionChange)="addGroup($event.value)"
                placeholder="Selecione um grupo">
                <mat-option *ngFor="let group of originalGroups" [value]="group">
                  {{ group.name }}
                </mat-option>
              </mat-select>

              <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4">
                <div *ngFor="let group of contact.groups"
                  class="bg-white rounded-lg shadow-global p-4 hover:shadow-lg transition-shadow">
                  <div class="flex justify-between items-center">
                    <div class="flex items-center gap-3">
                      <img class="w-10 h-10 rounded-full" src="../../../assets/images/logo-icon-sidebar.png"
                        [alt]="group.name">
                      <div>
                        <h3 class="font-medium text-gray-900">{{group.name}}</h3>
                      </div>
                    </div>

                    <button class="text-red-500 hover:text-red-700 focus:outline-none" title="Deletar grupo"
                      (click)="removeGroup(group)">
                      X
                    </button>
                  </div>
                </div>
              </div>
              <empty-records-component *ngIf="contact.groups.length <= 0" [message]="'Nenhum grupo adicionado'"
                [subMessage]="'Adicione um novo grupo acima'">
              </empty-records-component>

              <div class="w-full flex items-end justify-end">
                <button
                  class="h-[45px] flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[150px]"
                  (click)="saveContact(contact)">Salvar</button>
              </div>
            </div>
            <form [formGroup]="formGroup">
              <div *ngIf="navType === 'customerPortfolio'">
                <div class="mb-[15px]">
                  <h1 class="font-bold text-[1.2rem] mt-[10px] text-black">Carteira de Clientes</h1>
                  <h6 class="text-zinc-400 font-light text-sm">
                    Abaixo estão as opções para redirecionar o atendimento.
                  </h6>
                </div>
                <div class="flex gap-2 mt-[15px]">
                  <div class="flex flex-col flex-1">
                    <label class="font-semibold text-black">Departamento</label>
                    <mat-select class="input-field" formControlName="department" placeholder="Selecione"
                      (focus)="onFocusDepartments()" #department (selectionChange)="changeDepartment()">
                      <div class="w-full h-[35px] p-2 mb-2">
                        <input style="height: 35px !important" class="input-field" type="text"
                          [formControl]="filterControlDeparment" placeholder="Pesquise por um departamento..." />
                      </div>
                      <mat-option [value]="null">Selecione</mat-option>
                      @for (item of allDepartments; track item) {
                      <mat-option [value]="item._id">{{ item.name }}</mat-option>
                      }
                    </mat-select>
                    <field-error-component [field]="'department'" [label]="'Departamento'"
                      [formGroup]="formGroup"></field-error-component>
                  </div>
                </div>
                <div class="flex gap-2 mt-[15px]">
                  <div class="flex flex-col flex-1">
                    <label class="font-semibold text-black">Atendente</label>
                    <mat-select class="input-field" formControlName="attendant" placeholder="Selecione"
                      (focus)="onFocusAttendant()" #department>
                      <div class="w-full h-[35px] p-2 mb-2">
                        <input style="height: 35px !important" class="input-field" type="text"
                          [formControl]="filterControlAttendant" placeholder="Pesquise por um atendente..." />
                      </div>
                      <mat-option [value]="null">Selecione</mat-option>
                      @for (item of allAttendant; track item) {
                      <mat-option [value]="item._id">{{ item.name }}</mat-option>
                      }
                    </mat-select>
                    <field-error-component [field]="'attendant'" [label]="'Atendente'"
                      [formGroup]="formGroup"></field-error-component>
                  </div>
                </div>
                <div class="w-full flex items-end justify-end mt-4">
                  <button
                    class="h-[45px] flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[150px]"
                    (click)="saveContact(contact)">Salvar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div>

          <!--CONTATO-->

          <div class="w-[550px] rounded-lg shadow-global bg-white flex flex-col">
            <div class="w-full flex items-center p-3 justify-between">
              <div class="flex items-center">
                <img [src]="contact.image" alt="image user" class="w-[65px] rounded-full ml-2"
                  (error)="onImageError($event)" />
                <div class="ml-2">
                  <p class="font-bold">{{ contact.name }}</p>
                  <div class="mb-1" *ngIf="contact?.createdAt">
                    <p class="font-normal text-sm flex items-center text-gray-400">
                      Data da criação:
                      <span class="ml-1"> {{
                        contact?.createdAt | date : "dd/MM/yyyy"
                        }}</span>
                    </p>
                  </div>

                </div>


              </div>
              <div *ngIf="!isEditMode" class="rounded-lg p-2 text-white text-sm font-semibold items-end justify-end"
                [ngClass]="{
                'bg-green-500': contact?.valid === 'VALIDATED',
                'bg-yellow-500': contact?.valid === 'NOT_VALIDATED',
                'bg-red-500': contact?.valid === 'INVALID'
              }">
                {{ getContactStatus(contact?.valid) }}
              </div>
              <div *ngIf="!isEditMode" class="inline-block text-left" x-data="{ menu: false }">
                <button x-on:click="menu = ! menu" type="button"
                  class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none" id="menu-button"
                  aria-expanded="true" aria-haspopup="true">
                  <span class="sr-only"></span>
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                    aria-hidden="true">
                    <path
                      d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                  </svg>
                </button>
                <div x-show="menu" x-on:click.away="menu = false"
                  class="origin-top-right absolute right-20 w-40 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                  role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                  <div class="" role="none">
                    <a href="javascript:void(0)"
                      class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                      role="menuitem" tabindex="-1" id="menu-item-0"
                      (click)="editContact(contact); $event.stopPropagation()">
                      Editar
                    </a>
                    <a href="javascript:void(0)"
                      class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                      role="menuitem" tabindex="-1" id="menu-item-1" (click)="delete(contact); $event.stopPropagation()"
                      *ngIf="isAdmin()">
                      Remover
                    </a>
                  </div>
                </div>
              </div>
              <div class="inline-block text-left" *ngIf="isEditMode">
                <div class="flex gap-2">
                  <button
                    class="h-[40px] w-[80px] flex justify-center items-center rounded-lg  text-white font-normal   bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none"
                    (click)="saveContact(contact)">
                    Salvar
                  </button>
                  <button
                    class="h-[40px] w-[100px] flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100"
                    (click)="cancelEdit()">
                    Cancelar
                  </button>
                </div>
              </div>
            </div>

            <div class="p-4 border-t">

              <!--Telefone-->
              <div class="mb-2">
                <p class="font-normal text-sm flex items-center"
                  [ngClass]="{'text-[#5e5e5e]': isEditMode, 'text-gray-400': !isEditMode}">
                  @if ((isAttendant() && !getCurrentUserUser()?.clientVisibilitys) || (isSupervisor() || isAdmin())) {
                  <mat-icon class="text-base mr-1">phone</mat-icon>
                  Telefone:
                  <input *ngIf="isEditMode"
                    class="ml-1 bg-transparent border-b-[0.5px] border-gray-200 outline-none w-full focus:border-blue-500 focus:ring-0 transition-all duration-300 ease-in-out"
                    [(ngModel)]="editedContact.phone" />
                  }
                  @if ((isAttendant() && !getCurrentUserUser()?.clientVisibilitys) || (isSupervisor() || isAdmin())) {
                  <span *ngIf="!isEditMode" class="ml-1">{{ contact.phone || '-' }}</span>
                  }
                </p>
              </div>

              <!--Name-->
              <div class="mb-2">
                <p class="font-normal text-sm flex items-center"
                  [ngClass]="{'text-[#5e5e5e]': isEditMode, 'text-gray-400': !isEditMode}">
                  <mat-icon class="text-base mr-1">person</mat-icon>
                  Nome:
                  <input *ngIf="isEditMode"
                    class="ml-1 bg-transparent border-b-[0.5px] border-gray-200 outline-none w-full focus:border-blue-500 focus:ring-0 transition-all duration-300 ease-in-out"
                    [(ngModel)]="editedContact.name" />
                  <span *ngIf="!isEditMode" class="ml-1">{{ contact?.name || '-' }}</span>
                </p>
              </div>


              <!-- Email -->
              <div class="mb-2">
                <p class="font-normal text-sm flex items-center"
                  [ngClass]="{'text-[#5e5e5e]': isEditMode, 'text-gray-400': !isEditMode}">
                  <mat-icon class="text-base mr-1">email</mat-icon>
                  Email:
                  <input *ngIf="isEditMode"
                    class="ml-1 bg-transparent border-b-[0.5px] border-gray-200 outline-none w-full focus:border-blue-500 focus:ring-0 transition-all duration-300 ease-in-out"
                    [(ngModel)]="editedContact.email" />
                  <span *ngIf="!isEditMode" class="ml-1">{{ contact?.email || '-' }}</span>
                </p>
              </div>


              <!-- Empresa -->
              <div class="mb-2">
                <p class="font-normal text-sm flex items-center"
                  [ngClass]="{'text-[#5e5e5e]': isEditMode, 'text-gray-400': !isEditMode}">
                  <mat-icon class="text-base mr-1">business</mat-icon>
                  Empresa:
                  <input *ngIf="isEditMode"
                    class="ml-1 bg-transparent border-b-[0.5px] border-gray-200 outline-none w-full focus:border-blue-500 focus:ring-0 transition-all duration-300 ease-in-out"
                    [(ngModel)]="editedContact.contactCompany" />
                  <span *ngIf="!isEditMode" class="ml-1">{{ contact?.contactCompany || '-' }}</span>
                </p>
              </div>

              <!-- Cargo -->
              <div class="mb-2">
                <p class="font-normal text-sm flex items-center"
                  [ngClass]="{'text-[#5e5e5e]': isEditMode, 'text-gray-400': !isEditMode}">
                  <mat-icon class="text-base mr-1">work</mat-icon>
                  Cargo:
                  <input *ngIf="isEditMode"
                    class="ml-1 bg-transparent border-b-[0.5px] border-gray-200 outline-none w-full focus:border-blue-500 focus:ring-0 transition-all duration-300 ease-in-out"
                    [(ngModel)]="editedContact.contactPosition" />
                  <span *ngIf="!isEditMode" class="ml-1">{{ contact?.contactPosition || '-' }}</span>
                </p>
              </div>

              <!-- CPF -->
              <div class="mb-2">
                <p class="font-normal text-sm flex items-center"
                  [ngClass]="{'text-[#5e5e5e]': isEditMode, 'text-gray-400': !isEditMode}">
                  <mat-icon class="text-base mr-1">badge</mat-icon>
                  CPF:
                  <input *ngIf="isEditMode"
                    class="ml-1 bg-transparent border-b-[0.5px] border-gray-200 outline-none w-full focus:border-blue-500 focus:ring-0 transition-all duration-300 ease-in-out"
                    [(ngModel)]="editedContact.tin" />
                  <span *ngIf="!isEditMode" class="ml-1">{{ formatTin(contact?.tin) || '-' }}</span>
                </p>
              </div>

              <!-- CEP -->
              <div class="mb-2">
                <p class="font-normal text-sm flex items-center"
                  [ngClass]="{'text-[#5e5e5e]': isEditMode, 'text-gray-400': !isEditMode}">
                  <mat-icon class="text-base mr-1">home</mat-icon>
                  CEP:
                  <input *ngIf="isEditMode"
                    class="ml-1 bg-transparent border-b-[0.5px] border-gray-200 outline-none w-full focus:border-blue-500 focus:ring-0 transition-all duration-300 ease-in-out"
                    [(ngModel)]="editedContact.zip" />
                  <span *ngIf="!isEditMode" class="ml-1">{{ contact?.zip || '-' }}</span>
                </p>
              </div>

              <!-- Endereço -->
              <div class="mb-2">
                <p class="font-normal text-sm flex items-center"
                  [ngClass]="{'text-[#5e5e5e]': isEditMode, 'text-gray-400': !isEditMode}">
                  <mat-icon class="text-base mr-1">location_on</mat-icon>
                  Endereço:
                  <input *ngIf="isEditMode"
                    class="ml-1 bg-transparent border-b-[0.5px] border-gray-200 outline-none w-full focus:border-blue-500 focus:ring-0 transition-all duration-300 ease-in-out"
                    [(ngModel)]="editedContact.address" />
                  <span *ngIf="!isEditMode" class="ml-1">{{ contact?.address || '-' }}</span>
                </p>
              </div>

              <!-- Cidade -->
              <div class="mb-2">
                <p class="font-normal text-sm flex items-center"
                  [ngClass]="{'text-[#5e5e5e]': isEditMode, 'text-gray-400': !isEditMode}">
                  <mat-icon class="text-base mr-1">location_city</mat-icon>
                  Cidade:
                  <input *ngIf="isEditMode"
                    class="ml-1 bg-transparent border-b-[0.5px] border-gray-200 outline-none w-full focus:border-blue-500 focus:ring-0 transition-all duration-300 ease-in-out"
                    [(ngModel)]="editedContact.city" />
                  <span *ngIf="!isEditMode" class="ml-1">{{ contact?.city || '-' }}</span>
                </p>
              </div>

              <!-- Estado -->
              <div class="mb-2">
                <p class="font-normal text-sm flex items-center"
                  [ngClass]="{'text-[#5e5e5e]': isEditMode, 'text-gray-400': !isEditMode}">
                  <mat-icon class="text-base mr-1">map</mat-icon>
                  Estado:
                  <input *ngIf="isEditMode"
                    class="ml-1 bg-transparent border-b-[0.5px] border-gray-200 outline-none w-full focus:border-blue-500 focus:ring-0 transition-all duration-300 ease-in-out"
                    [(ngModel)]="editedContact.state" />
                  <span *ngIf="!isEditMode" class="ml-1">{{ contact?.state || '-' }}</span>
                </p>
              </div>

              <!-- Nascimento -->
              <div class="mb-2">
                <p class="font-normal text-sm flex items-center"
                  [ngClass]="{'text-[#5e5e5e]': isEditMode, 'text-gray-400': !isEditMode}">
                  <mat-icon class="text-base mr-1">cake</mat-icon>
                  Nascimento:
                  <input *ngIf="isEditMode"
                    class="ml-1 bg-transparent border-b-[0.5px] border-gray-200 outline-none w-full focus:border-blue-500 focus:ring-0 transition-all duration-300 ease-in-out"
                    [matDatepicker]="picker" [(ngModel)]="editedContact.birthDate" placeholder="Selecione uma data..."
                    (click)="picker.open()" [readonly]="true" />
                  <span *ngIf="!isEditMode" class="ml-1">{{ (contact?.birthDate | date: 'dd/MM/yyyy') ?? '-' }}</span>
                  <mat-datepicker-toggle *ngIf="isEditMode" matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </p>
              </div>


            </div>
            <div class="w-full p-3 border-t">
              <button
                class="h-[45px] px-14 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-full mt-4"
                (click)="openNewConversation()">
                Abrir atendimento
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</div>